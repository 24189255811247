import { useActor } from "@xstate/react";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { useApp } from "src/contexts/AppContext";
import { NextPageWithLayout } from "pages/_app";
interface WithAuthenticatedProps {
  redirect?: string;
}
const withAuthenticated = ({
  redirect
}: WithAuthenticatedProps = {
  redirect: "/"
}) => <T extends JSX.IntrinsicAttributes & {
  children?: ReactNode;
},>(Component: NextPageWithLayout) => {
  // Inject layout if defined.
  const fn = (hocProps: T) => {
    const router = useRouter();
    const {
      appService
    } = useApp();
    const [appState] = useActor(appService);
    const isAuthenticated = appState.matches({
      authentication: {
        init: "authenticated"
      }
    });
    const {
      paymentStatus,
      auth
    } = appState.context;

    // Redirect if state changes
    useEffect(() => {
      if (router.isReady && isAuthenticated && paymentStatus !== null && (paymentStatus?.isActive || paymentStatus?.isTrial || paymentStatus?.paymentExempt)) {
        router.replace(redirect!);
        return;
      } else if (router.isReady && isAuthenticated && paymentStatus !== null) {
        if (auth.userType === "CastingDirector" && !paymentStatus?.isSubscribedForPayment && !paymentStatus?.isPaymentDetailsCollectionInitiated) {
          router.replace("/account-paused");
          return;
        }
        if (auth.userType === "Agent" && !paymentStatus?.isSubscribedForPayment && !paymentStatus?.isPaymentDetailsCollectionInitiated) {
          router.replace("/account-paused");
          return;
        }
        if ((auth.userType === "Artist" || auth.userType === "FreelanceArtist") && paymentStatus.isFirstLoginToLiteTier) {
          router.replace("/pricing-table");
          return;
        } else {
          router.replace(redirect!);
          return;
        }
      }
    }, [router, isAuthenticated, paymentStatus]);
    return <Component {...hocProps} data-sentry-element="Component" data-sentry-component="fn" data-sentry-source-file="withAuthenticated.tsx" />;
  };

  // Re-map Layout for _app
  fn.Layout = Component.Layout;
  fn.Provider = Component.Provider;
  fn.SecondaryProvider = Component.SecondaryProvider;
  return fn;
};
export default withAuthenticated;