import { useTranslation } from "react-i18next";
import { useActor } from "@xstate/react";
import Head from "next/head";
import type { NextPageWithLayout } from "./_app";
import LayoutLogin from "src/components/LayoutLogin";
import ViewLogin from "src/components/ViewLogin";
import TextField from "src/components/TextField";
import LoginPanel from "src/structures/LoginPanel";
import schema, { initialValues, LoginProps } from "src/validations/login";
import Button from "src/components/Button";
import { useApp } from "src/contexts/AppContext";
import withAuthenticated from "src/hoc/withAuthenticated";
import FullScreenLoader from "src/components/FullScreenLoader";
import { AppTag } from "src/machines/appMachine";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { REGISTRATION, VALIDATIONS } from "src/i18n/constants/namespaces";
import toInvalidLoginMessage from "src/structures/LoginPanel/utils/toInvalidLoginMessage";
const Login: NextPageWithLayout = () => {
  const {
    t: getTranslation
  } = useTranslation([REGISTRATION, VALIDATIONS]);
  const router = useRouter();
  const {
    appService
  } = useApp();
  const [state, send] = useActor(appService);
  const {
    handleSubmit,
    control: formControl
  } = useForm<LoginProps>({
    resolver: yupResolver(schema),
    defaultValues: initialValues
  });
  const onSubmitHandler = (values: LoginProps) => {
    send({
      type: "AUTH_LOGIN",
      email: values.email,
      password: values.password
    });
  };
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="login.tsx">
      <meta name="facebook-domain-verification" content="qtadpv9f5kyaemtkv8ykrbaymb24cn" data-sentry-element="meta" data-sentry-source-file="login.tsx" />
        <title>{getTranslation("login.headTitle", {
          ns: REGISTRATION
        })}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <FullScreenLoader loading={state.hasTag(AppTag.loading)} data-sentry-element="FullScreenLoader" data-sentry-source-file="login.tsx" />
      <ViewLogin data-sentry-element="ViewLogin" data-sentry-source-file="login.tsx">
        <ViewLogin.Login data-sentry-element="unknown" data-sentry-source-file="login.tsx">
          <LoginPanel helperText={toInvalidLoginMessage(state.context.auth.errorCode)} data-sentry-element="LoginPanel" data-sentry-source-file="login.tsx">
            <LoginPanel.Form noValidate onSubmit={handleSubmit(onSubmitHandler)} data-sentry-element="unknown" data-sentry-source-file="login.tsx">
              <LoginPanel.FormItem data-sentry-element="unknown" data-sentry-source-file="login.tsx">
                <TextField label={getTranslation("login.label", {
                context: "email",
                ns: REGISTRATION
              })} id="email" name="email" required control={formControl} data-sentry-element="TextField" data-sentry-source-file="login.tsx" />
              </LoginPanel.FormItem>
              <LoginPanel.FormItem lastItem data-sentry-element="unknown" data-sentry-source-file="login.tsx">
                <TextField label={getTranslation("login.label", {
                context: "password",
                ns: REGISTRATION
              })} type="password" id="password" name="password" required control={formControl} data-sentry-element="TextField" data-sentry-source-file="login.tsx" />
              </LoginPanel.FormItem>
              <LoginPanel.FormLink data-sentry-element="unknown" data-sentry-source-file="login.tsx">
                <Link underline="none" href="#" onClick={() => router.push("/reset-password")} data-sentry-element="Link" data-sentry-source-file="login.tsx">
                  <Typography variant="body1" data-sentry-element="Typography" data-sentry-source-file="login.tsx">
                    {getTranslation("login.resetPassword", {
                    ns: REGISTRATION
                  })}
                  </Typography>
                </Link>
              </LoginPanel.FormLink>
              <LoginPanel.FormItem data-sentry-element="unknown" data-sentry-source-file="login.tsx">
                <Button type="submit" fullWidth variant="primary" data-sentry-element="Button" data-sentry-source-file="login.tsx">
                  {getTranslation("login.button", {
                  context: "login",
                  ns: REGISTRATION
                })}
                </Button>
              </LoginPanel.FormItem>
            </LoginPanel.Form>
          </LoginPanel>
        </ViewLogin.Login>
        <ViewLogin.Background data-sentry-element="unknown" data-sentry-source-file="login.tsx" />
      </ViewLogin>
    </>;
};
Login.Layout = LayoutLogin;

// If authenticated move to dashboard
export default withAuthenticated({
  redirect: "/"
})(Login);